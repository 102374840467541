import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = Object.freeze({
  open: false,
  title: "",
  content: "",
  type: "",
  errorCode: "",
  errorMessage: "",
})
const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<{ title: string; content: string }>) {
      state.open = true
      state.title = action.payload.title
      state.content = action.payload.content
      state.type = "info"
    },
    setWarning(
      state,
      action: PayloadAction<{
        title: string
        content: string
        errorCode?: string
        errorMessage?: string
      }>
    ) {
      state.open = true
      state.title = action.payload.title
      state.content = action.payload.content
      state.type = "warning"
      state.errorCode = action.payload.errorCode || ""
      state.errorMessage = action.payload.errorMessage || ""
    },
    setGeneralError(
      state,
      action: PayloadAction<
        | {
            errorCode?: string
            errorMessage?: string
          }
        | undefined
      >
    ) {
      state.open = true
      state.title = "Service unavailable"
      state.content =
        "This service is temporary unavailable. Call us for any assistance"
      state.type = "warning"
      state.errorCode = action.payload?.errorCode || ""
      state.errorMessage = action.payload?.errorMessage || ""
    },
    setClose() {
      return initialState
    },
  },
})

export default dialogSlice.reducer

const { setOpen, setWarning, setGeneralError, setClose } = dialogSlice.actions
export const dialogActions = {
  setOpen,
  setWarning,
  setGeneralError,
  setClose,
}
