import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { ReactNode } from "react"
import Close from "@icons/close"

type Props = {
  title: string
  children: ReactNode
  open: boolean
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
  onClose: () => void
  titleIcon?: ReactNode | undefined
  closeIcon?: boolean
} & (
  | { primaryButtonText: string; onPrimarySubmit: () => void }
  | { primaryButtonText?: never; onPrimarySubmit?: never }
) &
  (
    | { secondaryButtonText: string; onSecondarySubmit: () => void }
    | { secondaryButtonText?: never; onSecondarySubmit?: never }
  )

function AppDialog(props: Props): JSX.Element {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth={props.maxWidth}
    >
      <DialogTitle>
        <Box sx={{ textAlign: "right" }}>
          {props.closeIcon ? (
            <IconButton onClick={props.onClose}>
              <Close />
            </IconButton>
          ) : null}
        </Box>
        {props.titleIcon ? <Box>{props.titleIcon}</Box> : ""}
        <Typography>{props.title}</Typography>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.secondaryButtonText && (
          <Button
            variant="text"
            color="inherit"
            onClick={props.onSecondarySubmit}
          >
            {props.secondaryButtonText}
          </Button>
        )}
        {props.primaryButtonText && (
          <Button
            color="primary"
            variant="contained"
            onClick={props.onPrimarySubmit}
          >
            {props.primaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

AppDialog.defaultProps = {
  titleIcon: undefined,
  closeIcon: true,
  maxWidth: "lg",
}

export default AppDialog
