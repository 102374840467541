import React from "react"

import { SvgIcon, SvgIconProps } from "@mui/material"

function Help(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 0 C 5.371094 0 0 5.375 0 12 C 0 18.625 5.371094 24 12 24 C 18.628906 24 24 18.625 24 12 C 24 5.375 18.628906 0 12 0 Z M 12 22.398438 C 6.265625 22.398438 1.601562 17.734375 1.601562 12 C 1.601562 6.265625 6.265625 1.601562 12 1.601562 C 17.734375 1.601562 22.398438 6.265625 22.398438 12 C 22.398438 17.734375 17.734375 22.398438 12 22.398438 Z M 12 16.667969 C 11.261719 16.667969 10.664062 17.265625 10.664062 18 C 10.664062 18.734375 11.261719 19.332031 12 19.332031 C 12.734375 19.332031 13.332031 18.734375 13.332031 18 C 13.332031 17.265625 12.734375 16.667969 12 16.667969 Z M 12.804688 4.71875 C 11.667969 4.484375 10.5 4.757812 9.609375 5.484375 C 8.71875 6.207031 8.210938 7.28125 8.210938 8.425781 L 9.808594 8.425781 C 9.808594 7.765625 10.105469 7.144531 10.617188 6.726562 C 11.140625 6.300781 11.800781 6.144531 12.480469 6.285156 C 13.328125 6.460938 14.019531 7.1875 14.160156 8.050781 C 14.277344 8.765625 14.054688 9.46875 13.550781 9.972656 L 12.015625 11.503906 C 11.667969 11.859375 11.199219 12.433594 11.199219 13.347656 L 11.199219 14.667969 L 12.800781 14.667969 L 12.800781 13.347656 C 12.800781 13.113281 12.855469 12.933594 13.148438 12.636719 L 14.679688 11.105469 C 15.554688 10.230469 15.941406 9.023438 15.738281 7.789062 C 15.488281 6.265625 14.308594 5.035156 12.804688 4.71875 Z M 12.804688 4.71875 " />
    </SvgIcon>
  )
}

export default Help
