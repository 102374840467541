import { StrictMode } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { Provider } from "react-redux"
import reportWebVitals from "./reportWebVitals"
import { store } from "./store/store"
import App from "./App"
import "./assets/i18n/i18n"
import { setupInterceptor } from "./utils/http-client/app-client"
import { globalConfig, globalConfigPath } from "./utils/config/config"
import axios from "axios"
import { Typography } from "@mui/material"

const { dispatch } = store

axios
  .get(globalConfigPath)
  .then((response) => {
    globalConfig.set(response.data)
    setupInterceptor(dispatch)
    return (
      <StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </StrictMode>
    )
  })
  .catch(() => (
    <Typography sx={{ color: "red", textAlign: "center" }}>
      Error loading global config files
    </Typography>
  ))
  .then((reactElement) => {
    ReactDOM.render(reactElement, document.getElementById("root"))
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
