import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { LoginService } from "@services"
import {
  CheckLoginRequestBody,
  LoginRecordRequestBody,
  StatementServiceGetStatementDetailRequestBody,
} from "../../utils/type/login.type"

type LoginForm = {
  statementUuid: string
  nationalId: string
  dateOfBirth: string
}

type LoginState = {
  uuidExists: boolean
  loginForm: LoginForm
  statementDetail: string
  loggedIn: boolean
  cardEligible: boolean
  nationalIdCheck: boolean
  dateOfBirthCheck: boolean
}

type StatementServiceGetStatementByUuidRequestBody = {
  uuid: string
  requestBody: StatementServiceGetStatementDetailRequestBody
}

const initialState: LoginState = {
  uuidExists: false,
  loggedIn: false,
  loginForm: {
    statementUuid: "",
    nationalId: "",
    dateOfBirth: "",
  },
  statementDetail: "",
  cardEligible: false,
  nationalIdCheck: false,
  dateOfBirthCheck: false,
}

const existByUuid = createAsyncThunk(
  "statementService/existByUuid",
  async (uuid: string) => {
    const { data } = await LoginService.existByUuid(uuid)
    return data
  }
)

const getStatementByUuid = createAsyncThunk(
  "statementService/getStatementByUuid",
  async (requestBody: StatementServiceGetStatementByUuidRequestBody) => {
    const { data } = await LoginService.getStatementByUuid(
      requestBody.uuid,
      requestBody.requestBody
    )
    return data
  }
)

const checkCardEligible = createAsyncThunk(
  "statementService/checkCardEligible",
  async (uuid: string) => {
    const { data } = await LoginService.checkCardEligible({ uuid })
    return data
  }
)

const createLoginRecord = createAsyncThunk(
  "statementService/createLoginRecord",
  async (requestBody: LoginRecordRequestBody) => {
    const { data } = await LoginService.createLoginRecord(requestBody)
    return data
  }
)

const checkLogin = createAsyncThunk(
  "statementService/checkLogin",
  async (requestBody: CheckLoginRequestBody) => {
    const { data } = await LoginService.checkLogin(requestBody)
    return data
  }
)

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setStatementUuid(state, action: PayloadAction<string>) {
      state.loginForm.statementUuid = action.payload
    },
    setNationalId(state, action: PayloadAction<string>) {
      state.loginForm.nationalId = action.payload
    },
    setBirthDate(state, action: PayloadAction<string>) {
      state.loginForm.dateOfBirth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(existByUuid.fulfilled, (state, action) => {
        state.uuidExists = action.payload.payload.exist
      })
      .addCase(getStatementByUuid.fulfilled, (state, action) => {
        state.statementDetail = action.payload.payload.statementDetail
        state.loggedIn = true
      })
      .addCase(checkCardEligible.fulfilled, (state, action) => {
        state.cardEligible = action.payload.payload
      })
      .addCase(checkLogin.fulfilled, (state, action) => {
        // eslint-disable-next-line prefer-destructuring
        state.nationalIdCheck = action.payload.payload[0]
        // eslint-disable-next-line prefer-destructuring
        state.dateOfBirthCheck = action.payload.payload[1]
      })
  },
})

export const loginActions = {
  actions: loginSlice.actions,
  existByUuid,
  getStatementByUuid,
  checkCardEligible,
  createLoginRecord,
  checkLogin,
}

export default loginSlice.reducer
