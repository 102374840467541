import AppClient from "@client"
import { ApiResponse } from "../utils/type/api-response.type"
import {
  HomeRequestBody,
  HomeResponseBody,
} from "../utils/type/statement-service.type"

export const getCardsByStatementUuid = (
  requestBody: HomeRequestBody
): ApiResponse<HomeResponseBody> => {
  return AppClient.post(
    `/api/v1/statements/${requestBody.statementUuid}/cards`,
    {
      nationalId: requestBody.nationalId,
      dateOfBirth: requestBody.dateOfBirth,
    }
  )
}

export default getCardsByStatementUuid
