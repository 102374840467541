export default Object.freeze({
  NATIONAL_ID_INVALID: "Invalid national ID.",
  NATIONAL_ID_INCORRECT: "Incorrect national ID.",
  NATIONAL_ID_MANDATORY: "National ID is required.",
  DATE_OF_BIRTH_INVALID: "Invalid date of birth.",
  DATE_OF_BIRTH_MANDATORY: "Date of birth is required.",
  CAPTCHA_MANDATORY: "Captcha is required.",
  CAPTCHA_INVALID: "Incorrect Captcha.",
  TNC_ACCEPT: "Please accept Terms and Conditions to proceed the registration.",
})
