import { createSlice } from "@reduxjs/toolkit"

const initialState = Object.freeze({
  open: false,
})

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setOpen(state) {
      state.open = true
    },
    setClose(state) {
      state.open = false
    },
  },
})

export const loadingActions = loadingSlice.actions

export default loadingSlice.reducer
