import { Suspense } from "react"
import { HashRouter } from "react-router-dom"
import { Box, CssBaseline, ThemeProvider } from "@mui/material"
import AppRouter from "./app-router"
import AppTheme from "./assets/theme/hsbc-theme"
import DialogContainer from "./components/dialog.container"
import LoadingContainer from "./components/loading.container"

function App() {
  return (
    <HashRouter>
      <ThemeProvider theme={AppTheme.hsbcTheme()}>
        <CssBaseline />
        <Box height="100vh">
          <Suspense fallback={<h1>Loading Apps...</h1>}>
            <AppRouter />
          </Suspense>
        </Box>
        <DialogContainer />
        <LoadingContainer />
      </ThemeProvider>
    </HashRouter>
  )
}

export default App
