import { Backdrop, CircularProgress } from "@mui/material"

type Props = {
  loading: boolean
}

function AppLoader(props: Props): JSX.Element {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.loading}
    >
      <CircularProgress />
    </Backdrop>
  )
}

export default AppLoader
