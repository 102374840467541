import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { LOCALES } from "@constants"
import en from "./en/index.json"
import zh from "./zh_tw/index.json"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      [LOCALES.EN]: { translation: en },
      [LOCALES.ZH]: { translation: zh },
    },
    lng: LOCALES.EN,
    fallbackLng: LOCALES.EN,
  })

export default i18n
