import Axios, { AxiosError } from "axios"
import { Dispatch } from "@reduxjs/toolkit"
import { ApiErrorResponse, ErrorResponse } from "./type/api-response.type"
import { dialogActions } from "../store/slices/dialog.slice"

function handleAxiosError(
  dispatch: Dispatch,
  error: AxiosError
): undefined | ErrorResponse {
  const { config } = error
  const { baseURL = "", url = "", method } = config
  const finalUrl = `${method} ${baseURL}${url}`

  // handle connection timeout
  // due to the api response more than 60sec,
  // axios aborted the connection
  if (error.code === "ECONNABORTED") {
    console.warn("Axios Connection Timeout:", finalUrl)
    dispatch(
      dialogActions.setWarning({
        title: "Unable connect to server",
        content: "Please check your internet connection and try again.",
      })
    )
    return undefined
  }

  // handle cannot connect to the server
  if (error.name === "Error" && error.message === "Network Error") {
    console.log("Could not connect to the server:", finalUrl)
    dispatch(
      dialogActions.setWarning({
        title: "Unable connect to server",
        content: "Please check your internet connection and try again.",
      })
    )
    return undefined
  }

  // handle when the backend server have response
  if (error.response && error.response.data) {
    const apiResponse = error.response.data as ApiErrorResponse

    if (apiResponse.error.code) {
      console.warn(
        "see for more detail: ",
        apiResponse.error.code,
        "https://confluence.worldline.com/display/HSBCTWDBP/Error+Handling"
      )
    }
    return apiResponse.error
  }

  // handle unexpected error
  console.warn("===handleAxiosError===", { error })
  dispatch(dialogActions.setGeneralError())
  return undefined
}

function handleUnexpectedError(dispatch: Dispatch, error: any): undefined {
  console.warn("===handleUnexpectedError===", { error })
  dispatch(dialogActions.setGeneralError())
  return undefined
}

/**
 *
 * @param dispatch - redux dispatch, use to open dialog
 * @param handler - when provide the handler, will use the handler to process error, else will show general error dialog.
 */
export function handleErrorWithDispatch(
  dispatch: Dispatch,
  handler?: (errorResponse: ErrorResponse) => void
) {
  return function internalErrorHandler(error: any) {
    if (error) {
      if (handler) {
        handler(error)
      } else {
        dispatch(
          dialogActions.setGeneralError({
            errorCode: error.code,
            errorMessage: error.message,
          })
        )
      }
    } else {
      // error handled in interceptor
    }
  }
}

// handle connection timeout and non api error.
// will return ErrorResponse object from DBP backend, or undefined if handled.
export function handleErrorInterceptor(
  dispatch: Dispatch,
  error: any
): ErrorResponse | undefined {
  if (Axios.isAxiosError(error)) {
    return handleAxiosError(dispatch, error as AxiosError)
  }
  return handleUnexpectedError(dispatch, error)
}
