import Axios, { AxiosError } from "axios"
import { ENVIRONMENT } from "@constants"
import { Dispatch } from "@reduxjs/toolkit"
import { handleErrorInterceptor } from "../error.util"

const appClient = Axios.create({
  timeout: 60 * 1000, // 60 sec timeout
  headers: {
    "Content-Type": "application/json",
  },
})

export default appClient

export function setupInterceptor(dispatch: Dispatch) {
  appClient.interceptors.response.use(
    (value) => {
      return value
    },
    (error: AxiosError) => {
      const apiError = handleErrorInterceptor(dispatch, error)
      return Promise.reject(apiError)
    }
  )

  appClient.interceptors.request.use((value) => {
    // eslint-disable-next-line no-param-reassign
    value.baseURL = ENVIRONMENT.baseUrl()
    return value
  })
}
