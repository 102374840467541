import AppClient from "@client"
import {
  CheckLoginRequestBody,
  LoginRecordRequestBody,
  StatementServiceCheckCardEligibleRequestBody,
  StatementServiceCheckStatementResponseBody,
  StatementServiceGetStatementDetailRequestBody,
  StatementServiceGetStatementDetailResponseBody,
} from "../utils/type/login.type"
import { ApiResponse } from "../utils/type/api-response.type"

export const existByUuid = (
  statementUuid: string
): ApiResponse<StatementServiceCheckStatementResponseBody> => {
  return AppClient.get(`/api/v1/statements/${statementUuid}`)
}

export const getStatementByUuid = (
  statementUuid: string,
  requestBody: StatementServiceGetStatementDetailRequestBody
): ApiResponse<StatementServiceGetStatementDetailResponseBody> => {
  return AppClient.post(`/api/v1/statements/${statementUuid}`, {
    nationalId: requestBody.nationalId,
    dateOfBirth: requestBody.dateOfBirth,
  })
}

export const checkCardEligible = (
  requestBody: StatementServiceCheckCardEligibleRequestBody
): ApiResponse<boolean> => {
  return AppClient.post(`/api/v1/statements/check-card-eligible`, {
    uuid: requestBody.uuid,
  })
}

export const createLoginRecord = (
  requestBody: LoginRecordRequestBody
): ApiResponse<BigInteger> => {
  return AppClient.post("/api/v1/login", { nationalId: requestBody.nationalId })
}

export const checkLogin = (
  requestBody: CheckLoginRequestBody
): ApiResponse<Array<boolean>> => {
  return AppClient.post("/api/v1/login/validate", {
    uuid: requestBody.uuid,
    nationalId: requestBody.nationalId,
    dateOfBirth: requestBody.dateOfBirth,
  })
}
