export default Object.freeze({
  REGISTER: () => "/",
  LOGIN: () => "/login",
  HOME: () => "/s/home",
  TRANSACTION: () => "/s/transaction",
  PAYMENT: () => "/s/payment",
  PAYMENT_BAR_CODE: () => "/s/payment/bar-code",
  PAY: () => "/s/payment/bar-code/show",
  OTHERS_BANK_TRANSFER: () => "/s/payment/transfer",
  INFORMATION: () => "/s/information",
  NOT_FOUND: () => "/not-found",
})
