import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

function Transfer(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 0 C 5.371094 0 0 5.371094 0 12 C 0 18.628906 5.371094 24 12 24 C 18.628906 24 24 18.628906 24 12 C 24 5.371094 18.628906 0 12 0 Z M 12 22.398438 C 6.265625 22.398438 1.601562 17.734375 1.601562 12 C 1.601562 6.265625 6.265625 1.601562 12 1.601562 C 17.734375 1.601562 22.398438 6.265625 22.398438 12 C 22.398438 17.734375 17.734375 22.398438 12 22.398438 Z M 12.402344 12 L 14.664062 12 L 18.664062 8 L 14.664062 4 L 12.402344 4 L 15.601562 7.199219 L 5.332031 7.199219 L 5.332031 8.800781 L 15.601562 8.800781 Z M 11.597656 12 L 9.335938 12 L 5.335938 16 L 9.335938 20 L 11.597656 20 L 8.398438 16.800781 L 18.667969 16.800781 L 18.667969 15.199219 L 8.398438 15.199219 Z M 11.597656 12 " />
    </SvgIcon>
  )
}

export default Transfer
