import React, { useMemo } from "react"
import { BottomNavigation, BottomNavigationAction } from "@mui/material"
import Help from "@icons/help"
import Home from "@icons/home"
import Payment from "@icons/payment"
import Transfer from "@icons/transfer"
import { useMatch, useNavigate } from "react-router-dom"
import { ROUTES } from "@constants"

function AppBottomNavigation(): JSX.Element | null {
  const navigate = useNavigate()
  const matchHome = useMatch(ROUTES.HOME())
  const matchTransaction = useMatch(ROUTES.TRANSACTION())
  const matchPayment = useMatch(ROUTES.PAYMENT())
  const matchInfo = useMatch(ROUTES.INFORMATION())

  const home = "home"
  const transaction = "transaction"
  const payment = "payment"
  const info = "info"

  const navValue = useMemo(() => {
    if (matchHome) return home
    if (matchTransaction) return transaction
    if (matchPayment) return payment
    if (matchInfo) return info
    return ""
  }, [matchHome, matchInfo, matchPayment, matchTransaction])

  if (navValue === "") return null
  return (
    <BottomNavigation
      sx={{ borderTop: "#EEEEEE 2px solid" }}
      showLabels
      value={navValue}
      onChange={(event, newValue) => {
        switch (newValue) {
          case home:
            return navigate(ROUTES.HOME())
          case transaction:
            return navigate(ROUTES.TRANSACTION())
          case payment:
            return navigate(ROUTES.PAYMENT())
          case info:
            return navigate(ROUTES.INFORMATION())
          default:
            return navigate(ROUTES.NOT_FOUND())
        }
      }}
    >
      <BottomNavigationAction value={home} label="Home" icon={<Home />} />
      <BottomNavigationAction
        value={transaction}
        label="Transaction"
        icon={<Transfer />}
      />
      <BottomNavigationAction
        value={payment}
        label="Payment"
        icon={<Payment />}
      />
      <BottomNavigationAction value={info} label="Info" icon={<Help />} />
    </BottomNavigation>
  )
}

const defaultProps = {
  home: undefined,
  transaction: undefined,
  payment: undefined,
  info: undefined,
}

AppBottomNavigation.defaultProps = defaultProps

export default AppBottomNavigation
