import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

function Home(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 0 L 1.332031 10.667969 L 1.332031 24 L 10.667969 24 L 10.667969 16 L 13.332031 16 L 13.332031 24 L 22.667969 24 L 22.667969 10.667969 Z M 12 0 " />
    </SvgIcon>
  )
}

export default Home
