import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

function WarningRound(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        fill="rgb(100%,73.333333%,20%)"
        fillOpacity="1"
        d="M 24 12 C 24 18.628906 18.628906 24 12 24 C 5.371094 24 0 18.628906 0 12 C 0 5.371094 5.371094 0 12 0 C 18.628906 0 24 5.371094 24 12 Z M 24 12 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 13.601562 17.332031 C 13.601562 18.21875 12.882812 18.933594 12 18.933594 C 11.117188 18.933594 10.398438 18.21875 10.398438 17.332031 C 10.398438 16.449219 11.117188 15.734375 12 15.734375 C 12.882812 15.734375 13.601562 16.449219 13.601562 17.332031 Z M 13.601562 17.332031 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="rgb(0%,0%,0%)"
        fillOpacity="1"
        d="M 10.800781 5.066406 L 13.199219 5.066406 L 13.199219 13.066406 L 10.800781 13.066406 Z M 10.800781 5.066406 "
      />
    </SvgIcon>
  )
}

export default WarningRound
