import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

function InfoRound(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 0 C 18.628906 0 24 5.371094 24 12 C 24 18.628906 18.628906 24 12 24 C 5.371094 24 0 18.628906 0 12 C 0 5.371094 5.371094 0 12 0 Z M 13.199219 11 L 10.800781 11 L 10.800781 19 L 13.199219 19 Z M 12 5 C 11.082031 5 10.335938 5.746094 10.335938 6.667969 C 10.335938 7.585938 11.082031 8.332031 12 8.332031 C 12.921875 8.332031 13.667969 7.585938 13.667969 6.667969 C 13.667969 5.746094 12.921875 5 12 5 Z M 12 5 " />
    </SvgIcon>
  )
}

export default InfoRound
