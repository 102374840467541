import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HomeService } from "@services"
import { HomeRequestBody } from "../../utils/type/statement-service.type"
import { AppSelectItems } from "../../components/app-select/app-select"

type HomeState = {
  cardDetails: AppSelectItems[]
  selectedStatementUuid: string
}

const initialState: HomeState = Object.freeze({
  selectedStatementUuid: "",
  cardDetails: [
    {
      value: "",
      label: "",
    },
  ],
})

const getCardsByStatementUuid = createAsyncThunk(
  "home/getCardsByStatementUuid",
  async (createBody: HomeRequestBody) => {
    const { data } = await HomeService.getCardsByStatementUuid(createBody)
    return data
  }
)

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSelectedStatementUuid(state, action: PayloadAction<string>) {
      state.selectedStatementUuid = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCardsByStatementUuid.fulfilled, (state, action) => {
      state.cardDetails = action.payload.payload.items.map<AppSelectItems>(
        (it) => {
          return {
            value: it.statementUuid,
            label: `${it.cardName} - ${it.cardNumber.substring(
              it.cardNumber.length - 4
            )}`,
          }
        }
      )
    })
  },
})

export const homeActions = {
  actions: homeSlice.actions,
  getCardsByStatementUuid,
}

export default homeSlice.reducer
