import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

function CloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 21.898438 3.230469 L 20.769531 2.101562 L 12 10.871094 L 3.230469 2.101562 L 2.101562 3.230469 L 10.871094 12 L 2.101562 20.769531 L 3.230469 21.898438 L 12 13.128906 L 20.769531 21.898438 L 21.898438 20.769531 L 13.128906 12 Z M 21.898438 3.230469 " />
    </SvgIcon>
  )
}

export default CloseIcon
