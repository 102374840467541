const key = "accessToken"

export function getAccessToken(): string {
  return sessionStorage.getItem(key) || ""
}

export const saveAccessToken = (token: string) => {
  const bearer = "Bearer"
  const start = token.substring(0, bearer.length)

  if (bearer.toUpperCase() !== start.toUpperCase()) {
    sessionStorage.setItem(key, `${bearer} ${token}`)
  } else {
    sessionStorage.setItem(key, token)
  }
}

export const clearAccessToken = () => {
  sessionStorage.removeItem(key)
}
