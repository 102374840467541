import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import transactionReducer from "./slices/transaction.slice"
import homeReducer from "./slices/home.slice"
import loginReducer from "./slices/login.slice"
import dialogReducer from "./slices/dialog.slice"
import loadingReducer from "./slices/loading.slice"

export const store = configureStore({
  reducer: {
    transaction: transactionReducer,
    home: homeReducer,
    login: loginReducer,
    dialog: dialogReducer,
    loading: loadingReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
