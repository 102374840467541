import React from "react"
import { useAppSelector } from "../store/store"
import AppLoader from "./app-loader/app-loader"

function LoadingContainer() {
  const { open } = useAppSelector((state) => state.loading)

  return <AppLoader loading={open} />
}

export default LoadingContainer
