import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type TransactionForm = {
  txnDate: string
  postingDate: string
  txnLoc: string
  AMT: number
  amtCy: string
  cyCnvDate: string
  Desc: string
  NTD: number
}

type TransactionState = {
  transactionForm: TransactionForm
  transactionStep: number
}

const initialState: TransactionState = {
  transactionForm: {
    txnDate: "",
    postingDate: "",
    txnLoc: "",
    AMT: 0,
    amtCy: "",
    cyCnvDate: "",
    Desc: "",
    NTD: 0,
  },
  transactionStep: 1,
}

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactionStep(state, action: PayloadAction<number>) {
      state.transactionStep = action.payload
    },
    setTransactionForm(state, action: PayloadAction<TransactionForm>) {
      state.transactionForm = action.payload
    },
  },
  extraReducers: {},
})

export const transactionActions = transactionSlice.actions
export default transactionSlice.reducer
