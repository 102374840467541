import { createTheme } from "@mui/material"

// Size1
// fz = 33px/2.063em, leading = 40px/1.2em, paragraph = 20px/1.250em
// Size2
// fz = 28px/1.750em, leading = 36px/1.3em, paragraph = 18px/1.125em
// Size3
// fz = 23px/1.438em, leading = 30px/1.3em, paragraph = 15px/0.938em
// Size4
// fz = 19px/1.188em, leading = 27px/1.4em, paragraph = 14px/0.875em
// Size5
// fz = 16px/1em, leading = 24px/1.5em, paragraph = 12px/0.750em
// Size6
// fz = 14px/0.875em, leading = 20px/1.4em, paragraph = 10px/0.625em
// Size7
// fz = 12px/0.750em, leading = 16px/1.3em, paragraph = 8px/0.500em

// can add more https://mui.com/customization/default-theme/#main-content

const hsbcTheme = () =>
  createTheme({
    // Make all borderRadius for MUI component become 0.
    shape: { borderRadius: 0 },
    typography: {
      // Make default font to `UniversNextforHSBC`.
      fontFamily: "UniversNextforHSBC",
      // Custom <Typography/> variant.
      size1: { fontSize: "2.063rem" },
      size2: { fontSize: "1.750rem" },
      size3: { fontSize: "1.438rem" },
      size4: { fontSize: "1.188rem" },
      size5: { fontSize: "1rem" },
      size6: { fontSize: "0.875rem" },
      size7: { fontSize: "0.750rem" },
    },
    palette: {
      primary: { main: "#DC0009" },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          // default <Typography/> component will use `variant={"size5"}`.
          variant: "size5",
          // for variant size1-5 will use <div/> instead of <span/>.
          variantMapping: {
            size1: "div",
            size2: "div",
            size3: "div",
            size4: "div",
            size5: "div",
            size6: "div",
            size7: "div",
          },
        },
      },
      MuiButton: {
        defaultProps: { variant: "contained" },
        styleOverrides: {
          // Button label should not transform, should use i18n case instead.
          root: { textTransform: "none" },
          text: { color: "#4B4B4B" },
        },
      },
      // All input is size: small.
      MuiInputBase: {
        defaultProps: { size: "small" },
        styleOverrides: {
          root: {
            // all input will back red background when error.
            "&.Mui-error": {
              backgroundColor: "#F9F2F3",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: { root: { color: "#000000" } },
      },
      MuiFormLabel: {
        styleOverrides: { root: { color: "#000000" } },
      },
      MuiFormHelperText: {
        styleOverrides: { root: { color: "#000000" } },
      },
      MuiTooltip: { defaultProps: { arrow: true } },
    },
  })

export default { hsbcTheme }
