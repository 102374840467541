import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

function Payment(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 0 C 5.371094 0 0 5.371094 0 12 C 0 18.628906 5.371094 24 12 24 C 18.628906 24 24 18.628906 24 12 C 24 5.371094 18.628906 0 12 0 Z M 12 22.398438 C 6.265625 22.398438 1.601562 17.734375 1.601562 12 C 1.601562 6.265625 6.265625 1.601562 12 1.601562 C 17.734375 1.601562 22.398438 6.265625 22.398438 12 C 22.398438 17.734375 17.734375 22.398438 12 22.398438 Z M 12.085938 8.46875 C 13.796875 8.46875 13.808594 9.335938 13.8125 9.625 L 15.410156 9.601562 C 15.390625 8.121094 14.410156 7.136719 12.800781 6.917969 L 12.800781 5.332031 L 11.199219 5.332031 L 11.199219 6.953125 C 9.609375 7.242188 8.585938 8.300781 8.585938 9.78125 C 8.585938 11.773438 10.375 12.328125 11.332031 12.625 L 12.1875 12.898438 C 13.460938 13.296875 13.8125 13.578125 13.8125 14.21875 C 13.8125 15.4375 12.359375 15.53125 11.914062 15.53125 C 10.203125 15.53125 10.191406 14.664062 10.1875 14.375 L 8.585938 14.398438 C 8.609375 15.878906 9.589844 16.863281 11.199219 17.082031 L 11.199219 18.667969 L 12.796875 18.667969 L 12.796875 17.046875 C 14.390625 16.757812 15.410156 15.699219 15.410156 14.21875 C 15.410156 12.226562 13.621094 11.671875 12.660156 11.371094 L 11.8125 11.101562 C 10.535156 10.703125 10.1875 10.421875 10.1875 9.78125 C 10.1875 8.5625 11.640625 8.46875 12.085938 8.46875 Z M 12.085938 8.46875 " />
    </SvgIcon>
  )
}

export default Payment
