import { globalConfig } from "../config/config"

const ENVIRONMENT = {
  baseUrl: () => globalConfig.get().baseUrl,
  isProduction: () => globalConfig.get().isProduction,
  env: () => globalConfig.get().env,
  minioEndPoint: () => globalConfig.get().minioEndPoint,
  minioPort: () => globalConfig.get().minioPort,
  minioAccessKey: () => globalConfig.get().minioAccessKey,
  minioSecretKey: () => globalConfig.get().minioSecretKey,
  minioBucket: () => globalConfig.get().minioBucket,
  minioUseSsl: () => globalConfig.get().minioUseSsl,
}

export default ENVIRONMENT
