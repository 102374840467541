import React, { useMemo } from "react"
import { Box } from "@mui/material"
import WarningRound from "@icons/warning-round"
import InfoRound from "@icons/info-round"
import { useAppDispatch, useAppSelector } from "../store/store"
import { dialogActions } from "../store/slices/dialog.slice"
import AppDialog from "./app-dialog/app-dialog"
import EnvironmentConstant from "../utils/constants/environment.constant"

function DialogContainer() {
  const isProduction = EnvironmentConstant.isProduction()

  const dispatch = useAppDispatch()
  const dialog = useAppSelector((state) => state.dialog)

  const icon = useMemo(() => {
    switch (dialog.type) {
      case "warning":
        return <WarningRound />
      case "info":
        return <InfoRound />
      default:
        return null
    }
  }, [dialog.type])

  const handleClose = () => {
    dispatch(dialogActions.setClose())
  }
  return (
    <AppDialog
      open={dialog.open}
      closeIcon={false}
      title={dialog.title}
      titleIcon={icon}
      onClose={handleClose}
      primaryButtonText="Confirm"
      onPrimarySubmit={handleClose}
    >
      <div>{dialog.content}</div>
      <Box textAlign="right" fontSize="small">
        {dialog.errorCode && <div>Error Code: {dialog.errorCode}</div>}
        {!isProduction && dialog.errorMessage && (
          <div>{dialog.errorMessage}</div>
        )}
      </Box>
    </AppDialog>
  )
}

export default DialogContainer
