import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { ROUTES } from "@constants"
import { Box } from "@mui/material"
import { useAppSelector } from "../store/store"
import AppBottomNavigation from "../components/app-bottom-navigation/app-bottom-navigation"

function SecureLayout() {
  const isLogin = useAppSelector((state) => state.login.loggedIn)

  if (!isLogin) return <Navigate to={ROUTES.LOGIN()} replace />
  return (
    <Box display="flex" flexDirection="column" height="100vh" bgcolor="#EEEEEE">
      <Box flex={1} overflow="scroll">
        <Outlet />
      </Box>

      <AppBottomNavigation />
    </Box>
  )
}

SecureLayout.defaultProps = {
  children: undefined,
}
export default SecureLayout
