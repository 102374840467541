import { useRoutes } from "react-router-dom"
import { ROUTES } from "@constants"
import { lazy } from "react"
import NotFoundPage from "@pages/not-found.page"
import SecureLayout from "./layouts/ly-secure"

const RegistrationPage = lazy(
  () => import("@pages/Registration/registration.page")
)
const PaymentPage = lazy(() => import("@pages/payment/payment.page"))
const OthersBankTransferPage = lazy(
  () => import("@pages/payment/others-bank-transfer.page")
)
const PaymentBarCodePage = lazy(
  () => import("@pages/payment/payment-bar-code.page")
)
const PayPage = lazy(() => import("@pages/payment/pay.page"))
const Login = lazy(() => import("@pages/login/login.page"))
const Home = lazy(() => import("@pages/home/home.page"))
const Transaction = lazy(() => import("@pages/transaction/transaction.page"))
const Info = lazy(() => import("@pages/info/info.page"))

function AppRouter() {
  return useRoutes([
    { path: ROUTES.REGISTER(), element: <RegistrationPage /> },
    { path: ROUTES.LOGIN(), element: <Login /> },
    {
      path: "/s",
      element: <SecureLayout />,
      children: [
        { path: ROUTES.HOME(), element: <Home /> },
        { path: ROUTES.TRANSACTION(), element: <Transaction /> },
        { path: ROUTES.INFORMATION(), element: <Info /> },
        { path: ROUTES.PAYMENT(), element: <PaymentPage /> },
        { path: ROUTES.PAYMENT_BAR_CODE(), element: <PaymentBarCodePage /> },
        { path: ROUTES.PAY(), element: <PayPage /> },
        {
          path: ROUTES.OTHERS_BANK_TRANSFER(),
          element: <OthersBankTransferPage />,
        },
      ],
    },

    { path: "*", element: <NotFoundPage /> },
  ])
}

export default AppRouter
