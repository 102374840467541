export interface DynamicConfig {
  baseUrl: string
  isProduction: boolean
  env: string
  version: string
  minioEndPoint: string
  minioPort: number
  minioAccessKey: string
  minioSecretKey: string
  minioBucket: string
  minioUseSsl: boolean
}

export const defaultConfig: DynamicConfig = {
  baseUrl: "http://localhost:8091",
  isProduction: false,
  env: "local",
  version: "#BUILD#",
  minioEndPoint: "localhost",
  minioPort: 9000,
  minioAccessKey: "wladmin1",
  minioSecretKey: "P@ssw0rd",
  minioBucket: "hsbctw",
  minioUseSsl: false,
}

class GlobalConfig {
  config: DynamicConfig = defaultConfig // assign a value because of TypeScript

  public get(): DynamicConfig {
    return this.config
  }

  public set(value: DynamicConfig): void {
    this.config = value
  }
}

export const globalConfig = new GlobalConfig()

export const globalConfigPath = "config.json"
